import Popup from 'shared/ui/popup/popup';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open?: boolean;
  onPurchase?: MouseEventHandler;
};

const UserAlreadyRegistered = ({ open = false, onPurchase }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Popup.Title>{t('selfRegistration.userAlreadyRegisteredDialog.title')}</Popup.Title>
      <Popup.Description>
        {t('selfRegistration.userAlreadyRegisteredDialog.description')}
      </Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onPurchase}>
          {t('selfRegistration.userAlreadyRegisteredDialog.orderConfirmation')}
        </Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type MessageSentProps = {
  open?: boolean;
  onOk?: MouseEventHandler;
};

const MessageSent = ({ open = false, onOk }: MessageSentProps) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Popup.Title>{t('selfRegistration.messageSentDialog.title')}</Popup.Title>
      <Popup.Description>{t('selfRegistration.messageSentDialog.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('selfRegistration.messageSentDialog.OK')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

export const SelfRegistrationDialog = {
  UserAlreadyRegistered,
  MessageSent,
};
