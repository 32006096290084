import Popup from 'shared/ui/popup/popup';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open?: boolean;
  onMainScreen?: MouseEventHandler;
};

const Error500 = ({ open = false, onMainScreen }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Popup.Title>{t('errorDialog.500.title')}</Popup.Title>
      <Popup.Description>{t('errorDialog.500.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onMainScreen}>{t('errorDialog.500.onMainScreen')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type DocumentDownloadErrorProps = {
  open?: boolean;
  onBack?: MouseEventHandler;
};

const DocumentDownloadError = ({ open = false, onBack }: DocumentDownloadErrorProps) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Popup.Title>{t('documentDownloadErrorDialog.title')}</Popup.Title>
      <Popup.Description>{t('documentDownloadErrorDialog.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onBack}>{t('dialog.action.cancel')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

export const ErrorDialog = {
  Error500,
  DocumentDownloadError,
};
