import Popup from 'shared/ui/popup/popup';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

type Props = {
  open?: boolean;
  onOk?: MouseEventHandler;
  onCancel?: MouseEventHandler;
  description: string;
};

const CloseConfirmation = ({ open = false, onOk, onCancel, description }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={2}>
        <Popup.Description>{description}</Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onCancel}>{t('commonDialog.closeConfirmation.cancel')}</Popup.Action>
        <Popup.Action onClick={onOk}>{t('commonDialog.closeConfirmation.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

export const CommonDialog = {
  CloseConfirmation,
};
