import Popup from 'shared/ui/popup/popup';
import { MouseEventHandler, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

type Props = {
  open?: boolean;
  onOk?: MouseEventHandler;
};

const DeclineService = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={2}>
        <Popup.Description>{t('clientServiceDeclineDescription')}</Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const UnlimitedService = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={2}>
        <Popup.Description>{t('unlimitedServiceDescription')}</Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const UnlimitedServiceRo = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={2}>
        <Popup.Description>
          <Trans
            i18nKey={'unlimitedServiceDescription'}
            // components={[
            //   <a
            //     href={'http://kokao-backend.mokka.ro/legal_documents/downloads/informing'}
            //     target={'_blank'}
            //   />,
            // ]}
          />
        </Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const WrongPassport = ({
  open = false,
  onOk,
  type,
}: Props & { type?: 'default' | 'missing' | 'issue_date' | 'issuing_authority' }) => {
  const [step, setStep] = useState(0);
  const { t } = useTranslation();

  const country = process.env.NEXT_PUBLIC_COUNTRY;
  const isOpenDefault = open && step === 0;
  const isOpenAdditional = open && step === 1;
  return (
    <>
      <Popup.Container open={isOpenDefault}>
        <Box pt={2}>
          {!type ? (
            <Popup.Description>
              <Trans
                i18nKey={'wrongPassportDialog.description.default'}
                components={[
                  <b key={'0'} />,
                  <b key={'1'} />,
                  <b key={'2'} />,
                  <a
                    key={'3'}
                    href={`mailto:${t('wrongPassportDialog.mailto')}`}
                    target={'_blank'}
                    rel={'noopener noreferrer '}
                  />,
                ]}
              />
            </Popup.Description>
          ) : (
            <Popup.Description>
              <Trans
                i18nKey={'wrongPassportDialog.description.' + type}
                components={[
                  <b key={'0'} />,
                  <b key={'1'} />,
                  <b key={'2'} />,
                  <a
                    key={'3'}
                    href={`mailto:${t('wrongPassportDialog.mailto')}`}
                    target={'_blank'}
                    rel={'noopener noreferrer '}
                  />,
                ]}
              />
            </Popup.Description>
          )}
        </Box>
        <Popup.Actions>
          <Popup.Action
            onClick={(e) => {
              if (!type) {
                onOk && onOk(e);
              } else {
                if (step === 0) {
                  setStep(1);
                } else {
                  onOk && onOk(e);
                }
              }
            }}
          >
            {t('dialog.action.ok')}
          </Popup.Action>
        </Popup.Actions>
      </Popup.Container>
      <WrongPassportStep2 onOk={onOk} open={isOpenAdditional} />
    </>
  );
};

function WrongPassportStep2({ open = false, onOk }: Props) {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={2}>
        <Popup.Description>
          <Trans i18nKey={'wrongPassportDialogStep2.description'} components={[<b />]} />
        </Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
}

const CheckUserAndDocsField = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={2}>
        <Popup.Title>{t('check.docs.fields.title')}</Popup.Title>
        <Popup.Description>{t('check.docs.fields.description')}</Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const CheckAmountAndTerm = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={1}>
        <Popup.Title>{t('check.amount.title')}</Popup.Title>
        <Popup.Description>
          {
            <Trans
              i18nKey={'check.amount.description'}
              components={[<span key={0}></span>, <span key={1}></span>]}
            />
          }
        </Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const CheckAgreements = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Box pt={2}>
        <Popup.Title>{t('check.docs.fields.title')}</Popup.Title>
        <Popup.Description>{t('check.docs.fields.description')}</Popup.Description>
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type CheckClientDataProps = {
  open?: boolean;
  onAgree?: MouseEventHandler;
  onDecline?: MouseEventHandler;
  data: {
    fullName: string;
    documentIdentifier: string;
    birthDate?: string;
    egn?: string;
  };
};

const CheckClientData = ({ open = false, onAgree, onDecline, data }: CheckClientDataProps) => {
  const { t } = useTranslation();
  const country = process.env.NEXT_PUBLIC_COUNTRY;
  return (
    <Popup.Container open={open}>
      <Box
        p={2}
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
        }}
      >
        <Box
          sx={{
            fontWeight: 500,
          }}
        >
          {t('checkClientPassportData')}
        </Box>
        <Box
          pt={1}
          mt={1}
          sx={{
            borderTop: 'solid 1px #D0CED2',
            display: 'flex',
            gap: 3,
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
            }}
          >
            {t('client')}
          </Box>
          <Box
            sx={{
              textAlign: 'right',
              color: '#6D6F73',
              flex: 1,
            }}
          >
            {data.fullName}
          </Box>
        </Box>
        <Box
          pt={1}
          mt={1}
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
            }}
          >
            {t('documentIdentifier')}
          </Box>
          <Box
            sx={{
              textAlign: 'right',
              fontVariant: 'tabular-nums',
              color: '#6D6F73',
              flex: 1,
              whiteSpace: 'nowrap',
            }}
          >
            {data.documentIdentifier}
          </Box>
        </Box>
        {country === 'bg' && (
          <Box
            pt={1}
            mt={1}
            sx={{
              borderTop: 'solid 1px #D0CED2',
              display: 'flex',
              gap: 3,
            }}
          >
            <Box
              sx={{
                fontWeight: 500,
              }}
            >
              {t('egn')}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                fontVariant: 'tabular-nums',
                color: '#6D6F73',
                flex: 1,
                whiteSpace: 'nowrap',
              }}
            >
              {data.egn}
            </Box>
          </Box>
        )}
        {country === 'ru' && (
          <Box
            pt={1}
            mt={1}
            sx={{
              borderTop: 'solid 1px #D0CED2',
              display: 'flex',
              gap: 3,
            }}
          >
            <Box
              sx={{
                fontWeight: 500,
              }}
            >
              {t('birthDate')}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                fontVariant: 'tabular-nums',
                color: '#6D6F73',
                flex: 1,
                whiteSpace: 'nowrap',
              }}
            >
              {data.birthDate}
            </Box>
          </Box>
        )}
      </Box>
      <Popup.Actions>
        <Popup.Action onClick={onDecline}>{t('dialog.action.decline')}</Popup.Action>
        <Popup.Action onClick={onAgree}>{t('dialog.action.agree')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type ClientPhotoNotUploadedProps = {
  open: boolean;
  onHelp: MouseEventHandler;
  onCancel: MouseEventHandler;
};
export const ClientPhotoNotUploaded = ({ open, onHelp, onCancel }: ClientPhotoNotUploadedProps) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Popup.Title>{t('clientPhotoNotUploadedDialog.title')}</Popup.Title>
      <Popup.Description>
        <Trans
          i18nKey={'clientPhotoNotUploadedDialog.description'}
          components={[
            <Box
              key={1}
              component={'span'}
              onClick={onHelp}
              sx={{
                color: '#FF5A1E',
                textDecoration: 'underline',
              }}
            />,
          ]}
        />
      </Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onCancel}>{t('dialog.action.cancel')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

export const PurchaseDialog = {
  DeclineService,
  UnlimitedService,
  CheckClientData,
  ClientPhotoNotUploaded,
  WrongPassport,
  UnlimitedServiceRo,
  CheckUserAndDocsField,
  CheckAmountAndTerm,
  CheckAgreements,
};
