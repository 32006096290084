import { MouseEventHandler, PropsWithChildren } from 'react';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

type PopupProps = {
  open: boolean;
  onClose?: MouseEventHandler;
};

const Container = ({ children, onClose, open }: PropsWithChildren<PopupProps>) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ outline: '0 !important' }}
      sx={{
        outline: 'none !important',
        '.MuiBox-root': {
          outline: 'none !important',
          '&:focus-visible': {
            outline: 'none !important',
          },
        },
        '&:focus-visible': {
          outline: 'none !important',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          background: '#FFF',
          width: 'calc(100vw - 32px)',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          borderRadius: '4px',
          boxShadow:
            '0px 5.5px 16px 0px rgba(0, 0, 0, 0.19), -8px 20px 14px 0px rgba(0, 0, 0, 0.20)',
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

const Title = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      px={2}
      pb={3}
      pt={4}
      sx={{
        fontSize: '20px',
        lineHeight: '20px',
        fontWeight: 500,
      }}
    >
      {children}
    </Box>
  );
};

const Description = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      px={2}
      sx={{
        fontSize: '16px',
        lineHeight: '22px',
        a: { color: '#FF5A1E' },
      }}
    >
      {children}
    </Box>
  );
};

const Actions = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      px={2}
      pb={2}
      pt={3}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 2,
      }}
    >
      {children}
    </Box>
  );
};

type ActionProps = {
  onClick?: MouseEventHandler;
  href?: string;
};

const Action = ({ children, onClick, href }: PropsWithChildren<ActionProps>) => {
  return (
    <Button
      onClick={onClick}
      href={href}
      sx={{
        height: '24px',
        py: 0,
      }}
    >
      {children}
    </Button>
  );
};

const Popup = {
  Container,
  Title,
  Description,
  Actions,
  Action,
};

export default Popup;
