import Popup from 'shared/ui/popup/popup';
import { MouseEventHandler, PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  open?: boolean;
  onOk?: MouseEventHandler;
};

const ErrorTitle = () => {
  const { t } = useTranslation();
  return <Popup.Title>{t('dialog.error.title')}</Popup.Title>;
};

const UserNotFound = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{t('dialog.auth.userNotFound.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const AuthenticationFailed = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{t('dialog.auth.authenticationFailed.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const ServerError = ({
  open = false,
  onOk,
  children,
}: PropsWithChildren<{ open?: boolean; onOk: MouseEventHandler }>) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{children}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const TooManyAttempts = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{t('dialog.auth.tooManyAttempts.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type PhoneNumberNotRegisteredProps = {
  open?: boolean;
  onSignUp?: MouseEventHandler;
  onCancel?: MouseEventHandler;
};

const PhoneNumberNotRegistered = ({
  open = false,
  onSignUp,
  onCancel,
}: PhoneNumberNotRegisteredProps) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{t('dialog.auth.phoneNumberNotRegistered.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onCancel}>{t('dialog.action.back')}</Popup.Action>
        <Popup.Action onClick={onSignUp}>{t('dialog.action.signUp')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

const InvalidSmsCode = ({ open = false, onOk }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{t('dialog.auth.invalidSmsCode.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onOk}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type PasswordTooSimpleProps = {
  open?: boolean;
  onContinue?: MouseEventHandler;
  onCancel?: MouseEventHandler;
};

const PasswordTooSimple = ({ open = false, onContinue, onCancel }: PasswordTooSimpleProps) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{t('dialog.auth.passwordTooSimple.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onContinue}>{t('dialog.action.continue')}</Popup.Action>
        <Popup.Action onClick={onCancel}>{t('dialog.action.change')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type PasswordMismatchProps = {
  open?: boolean;
  onCancel?: MouseEventHandler;
};

const PasswordMismatch = ({ open = false, onCancel }: PasswordMismatchProps) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <ErrorTitle />
      <Popup.Description>{t('dialog.auth.passwordMismatch.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onCancel}>{t('dialog.action.ok')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

type SendEmailProps = {
  open?: boolean;
  onCancel?: MouseEventHandler;
  supportHref?: string;
};

const SendEmail = ({ open = false, supportHref, onCancel }: SendEmailProps) => {
  const { t } = useTranslation();

  return (
    <Popup.Container open={open}>
      <Popup.Title>{t('dialog.auth.forgotLogin')}</Popup.Title>
      <Popup.Description>
        <Trans
          i18nKey="dialog.auth.sendSupportMail"
          components={[<a key={0} href={'mailto:' + supportHref} />]}
          values={{
            supportHref,
          }}
        />
      </Popup.Description>
      <Popup.Actions>
        <Popup.Action href={'mailto:' + supportHref}>{t('dialog.auth.supportSend')}</Popup.Action>
        <Popup.Action onClick={onCancel}>{t('dialog.action.back')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

export const AuthDialog = {
  UserNotFound,
  AuthenticationFailed,
  TooManyAttempts,
  PhoneNumberNotRegistered,
  InvalidSmsCode,
  PasswordTooSimple,
  PasswordMismatch,
  SendEmail,
  ServerError,
};
