import Popup from 'shared/ui/popup/popup';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open?: boolean;
  onDecline?: MouseEventHandler;
  onCancel?: MouseEventHandler;
};

const Decline = ({ open = false, onDecline, onCancel }: Props) => {
  const { t } = useTranslation();
  return (
    <Popup.Container open={open}>
      <Popup.Title>{t('refund.declineDialog.title')}</Popup.Title>
      <Popup.Description>{t('refund.declineDialog.description')}</Popup.Description>
      <Popup.Actions>
        <Popup.Action onClick={onDecline}>{t('refund.declineDialog.declineButton')}</Popup.Action>
        <Popup.Action onClick={onCancel}>{t('refund.declineDialog.cancelButton')}</Popup.Action>
      </Popup.Actions>
    </Popup.Container>
  );
};

export const RefundDialog = {
  Decline,
};
